import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import Layout from '../../../components/site/Layout/Layout';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import Modal from '../../../components/core/Modal/Modal';
import { PAGE_ID, TEMP_DETAILS_L10N, GENERIC_L10N, DATA_LAYER_LISTS } from '../../../common/Constants';
import { getPageContent } from '../../../actions/PageContentAction';
import RestoreModalContent from '../../../components/site/RestoreModalContent/RestoreModalContent';
import CreateNewTemplate from '../../../components/site/CreateNewTemplate/CreateNewTemplate';
import TemplateDetailsForm from '../../../components/site/TemplateDetailsForm/TemplateDetailsForm';
import TemplateDetailsOrderItem from '../../../components/site/TemplateDetailsOrderItem/TemplateDetailsOrderItem';
import Button from '../../../components/core/Button/Button';
import Icon from '../../../components/core/Icon/Icon';
import ReactToPrint from 'react-to-print';

import {
    getTemplateDetails,
    updateTemplateName,
    updateTemplateDescription,
    updateOrderTemplate,
    cancelOrderTemplate,
    updateOrderTemplateItemQty,
    updateOrderTemplateQty,
    deleteOrderTemplateItem,
    clearTemplateMessage,
    resetTemplateDetails,
    setZeroQtyError,
    updateItemSequence,
    updateSortingOrderItem
} from '../../../actions/OrderTemplateDetailsAction';
import { addToCart } from '../../../actions/CartAction';

import * as styles from './OrderTemplateDetails.css';
import {
    viewItemListEventGA4,
    buttonClickEventConfigs,
    buttonClickEvent
} from '../../../common/GoogleTagManager/GoogleTagManager';
import { productImpressionsOrderTemplateDataLayerFillterGA4 } from '../../../service/DataLayerFilter';

const cx = classNames.bind(styles);

class OrderTemplateDetails extends Component {
    componentPrintRef = null;
    constructor() {
        super();
        this.state = {
            isEditMode: false,
            restoreModalData: null,
            copyModalData: null,
            totalQty: 0,
            updatedTotalQty: false
        };
    }

    componentDidMount() {
        const { templateId } = this.props.params;
        this.props.actions.getPageContent({ pageId: PAGE_ID.TEMPLATE_DETAILS, code: templateId });
    }

    componentDidUpdate(prevProps) {
        const { items } = this.props.orderTemplateData;
        const { items: preItems } = prevProps.orderTemplateData;
        const { updatedTotalQty } = this.state;

        if (!isEqual(items, preItems) || updatedTotalQty) this.setTotalQty(items);
        if (!isEqual(items, preItems)) {
            try {
                viewItemListEventGA4(
                    productImpressionsOrderTemplateDataLayerFillterGA4(
                        _cloneDeep(items),
                        DATA_LAYER_LISTS.TEMPLATE_LIST
                    )
                );
            } catch (ex) {
                console.error(ex);
            }
        }
    }

    componentWillUnmount() {
        this.props.actions.resetTemplateDetails();
    }

    getTotalQty = items => items.reduce((total, obj) => total + obj.qty, 0);

    setTotalQty = items => {
        const totalQty = this.getTotalQty(items);
        this.setState({ updatedTotalQty: false, totalQty });
    };

    getNonDeletedItemCount = items => items.filter(item => item.deleted !== true).length;

    setToViewMode = () => {
        this.setState({ isEditMode: false });
    };

    setToEditMode = e => {
        e.preventDefault();
        this.props.actions.cancelOrderTemplate();
        const { name, description } = this.props.orderTemplateData;
        this.handleChangeName(name);
        this.handleChangeDesc(description);
        this.setState({ isEditMode: true });
    };

    handleChangeName = name => {
        this.props.actions.updateTemplateName({ value: name });
    };

    handleChangeDesc = desc => {
        this.props.actions.updateTemplateDescription({ value: desc });
    };

    handleChangeQtyInput = data => {
        this.props.actions.updateOrderTemplateItemQty(data);
        this.setState({ updatedTotalQty: true });
    };

    handleUpdateQty = data => {
        const { id } = this.props.orderTemplateData;
        const reqData = {
            code: id,
            products: [
                {
                    productCode: data.item.id,
                    quantity: data.item.qty,
                    unitCode: data.item.unitCode
                }
            ]
        };

        this.props.actions.updateOrderTemplateQty({ code: id, data: reqData });
    };

    handleSequenceChange = (orderType, curIndex, itemData) => {
        const nextIndex = orderType === 'up' ? curIndex - 1 : curIndex + 1;
        //this.props.actions.updateItemSequence({ curIndex, nextIndex });
        const { orderTemplateData } = this.props;

        const reqData = {
            cartId: orderTemplateData.id,
            currentEntryIndex: curIndex,
            newEntryIndex: nextIndex
        };

        this.props.actions.updateSortingOrderItem({ data: reqData });
    };

    addToCartItem = data => {
        const { item } = data;
        const products = {
            code: item.id,
            quantity: item.qty,
            quantityType: item.unitCodeForBackend,
            isAdd: true
        };

        if (item.qty > 0) {
            this.props.actions.addToCart({ data: products });
            item.qty = 0;
            this.handleChangeQtyInput(item);
        } else {
            this.props.actions.setZeroQtyError({ id: item.id });
        }
    };

    handleRemoveItem = data => {
        this.props.actions.deleteOrderTemplateItem({ id: data.item.id });
    };

    removeAllItems = () => {
        this.props.actions.deleteOrderTemplateItem({ isRemoveAll: true });
    };

    handleTemplateEditDone = () => {
        const { orderTemplateData } = this.props;

        const reqData = {
            code: orderTemplateData.id,
            name: orderTemplateData.newName,
            description: orderTemplateData.newDescription,
            productList: [],
            isDeleteAll: false
        };

        reqData.productList = orderTemplateData.items.map((item, newIndex) => {
            return {
                productCode: item.id,
                quantity: item.qty,
                unitCode: item.unitCode,
                sequenceOrder: newIndex,
                entryNumber: item.index,
                oldSequenceOrder: item.index,
                isDeleted: item.deleted === true,
                quantityUpdated: item.qty !== item.displayQty
            };
        });

        reqData.isDeleteAll = orderTemplateData.items.length > 0 && orderTemplateData.nonDeletedItemCount === 0;

        this.props.actions.updateOrderTemplate({ code: orderTemplateData.id, data: reqData });
        this.setToViewMode();
    };

    handleTemplateEditCancel = () => {
        this.props.actions.cancelOrderTemplate();
        this.setToViewMode();
    };

    handleRestoreTemplate = e => {
        e.preventDefault();
        const { id, name, qty, items } = this.props.orderTemplateData;
        let totalQty = 0;
        const products = [];
        if (items.length) {
            for (let i = 0; i < items.length; i += 1) {
                if (items[i].qty > 0)
                    products.push({
                        productCode: items[i].id,
                        quantity: `${items[i].qty}`,
                        unitCode: items[i].unitCode
                    });
            }
            totalQty = this.getTotalQty(items);
        }
        this.setState({ restoreModalData: { id, name, qty, totalQty, products } });
    };

    handleCopyTemplate = e => {
        e.preventDefault();
        const { orderTemplateData } = this.props;
        const copyData = {
            id: orderTemplateData.id,
            name: `Copy-${orderTemplateData.name}`
        };
        this.setState({ copyModalData: copyData });
        if (window.lastPath?.startsWith('/account/shoppingList/savedCarts')) {
            buttonClickEvent(
                buttonClickEventConfigs.SLorderTemplates.actions.templateDetails,
                buttonClickEventConfigs.SLorderTemplates.labels.templateDetailsCopy,
                buttonClickEventConfigs.SLorderTemplates.categories.orderTemplates
            );
        } else {
            buttonClickEvent(
                buttonClickEventConfigs.orderTemplates.actions.templateDetails,
                buttonClickEventConfigs.orderTemplates.labels.templateDetailsCopy,
                buttonClickEventConfigs.orderTemplates.categories.orderTemplates
            );
        }
    };

    handleCopyModalClose = () => {
        this.setState({ copyModalData: false });
    };

    handleRestoreModalClose = () => {
        this.setState({ restoreModalData: null });
    };

    isFieldEmpty = () => {
        const { newName } = this.props.orderTemplateData;
        if (isEmpty(newName)) return true;

        return false;
    };

    reactToPrintContent = () => {
        return this.componentPrintRef;
    };

    renderPrintRestoreCopyButtons = templateData => {
        const { isEditMode } = this.state;
        return (
            <span className={cx('orderMode')}>
                {!isEditMode && (
                    <ul className={cx('tempDetailsinfo-actions', 'col4', 'sm12')}>
                        <li className={cx('print-actions')}>
                            <ReactToPrint
                                content={this.reactToPrintContent}
                                documentTitle='document'
                                removeAfterPrint
                                trigger={() => (
                                    <Button automationId='at-print-option' type='linkbutton' size='Sm'>
                                        {' '}
                                        <Icon
                                            iconType='svg'
                                            width='25px'
                                            height='24px'
                                            viewBox='0 0 25 24'
                                            name='print'
                                        />
                                    </Button>
                                )}
                                onBeforePrint={() => {
                                    if (window.lastPath?.startsWith('/account/shoppingList/savedCarts')) {
                                        buttonClickEvent(
                                            buttonClickEventConfigs.SLorderTemplates.actions.templateDetails,
                                            buttonClickEventConfigs.SLorderTemplates.labels.templateDetailsPrint,
                                            buttonClickEventConfigs.SLorderTemplates.categories.orderTemplates
                                        );
                                    } else {
                                        buttonClickEvent(
                                            buttonClickEventConfigs.orderTemplates.actions.templateDetails,
                                            buttonClickEventConfigs.orderTemplates.labels.templateDetailsPrint,
                                            buttonClickEventConfigs.orderTemplates.categories.orderTemplates
                                        );
                                    }
                                }}
                            />
                        </li>
                        <li className={cx('copy-actions')}>
                            <Button
                                automationId='at-copy-option'
                                type='linkbutton'
                                size='Sm'
                                onClick={e => this.handleCopyTemplate(e)}
                            >
                                <Icon iconType='svg' width='25' height='24' viewBox='0 0 25 24' name='copy-block' />
                            </Button>
                        </li>
                        <li className={cx('restore-actions', 'hiddenSm')}>
                            <Button
                                automationId='at-add-to-cart-option'
                                type='linkbutton'
                                size='Sm'
                                onClick={e => {
                                    this.setToEditMode(e);
                                    if (window.lastPath?.startsWith('/account/shoppingList/savedCarts')) {
                                        buttonClickEvent(
                                            buttonClickEventConfigs.SLorderTemplates.actions.templateDetails,
                                            buttonClickEventConfigs.SLorderTemplates.labels.templateDetailsUpdate,
                                            buttonClickEventConfigs.SLorderTemplates.categories.orderTemplates
                                        );
                                    } else {
                                        buttonClickEvent(
                                            buttonClickEventConfigs.orderTemplates.actions.templateDetails,
                                            buttonClickEventConfigs.orderTemplates.labels.templateDetailsUpdate,
                                            buttonClickEventConfigs.orderTemplates.categories.orderTemplates
                                        );
                                    }
                                }}
                            >
                                <Icon iconType='svg' width='25' height='24' viewBox='0 0 25 24' name='edit-block' />
                            </Button>
                        </li>
                    </ul>
                )}
            </span>
        );
    };

    renderEditTemplateButtons = () => {
        const { localization, commonLocalization } = this.props;
        const { isEditMode } = this.state;
        return (
            <div automation-id='edit-mode-text-button' className={cx('tempDetailsedit', 'col4', 'sm12')}>
                {isEditMode && (
                    <div className={cx('editActions')}>
                        <Button
                            automationId='at-done-button'
                            className={cx('done')}
                            type='button'
                            buttonType='Primary'
                            size='Sm'
                            isDisabled={this.isFieldEmpty()}
                            onClick={() => this.handleTemplateEditDone()}
                        >
                            {' '}
                            {localization[TEMP_DETAILS_L10N.UPDATE_BTN]}
                        </Button>
                        <Button
                            automationId='at-cancel-button'
                            className={cx('cancel')}
                            type='button'
                            buttonType='Primary'
                            size='Sm'
                            onClick={() => this.handleTemplateEditCancel()}
                        >
                            {' '}
                            {commonLocalization[GENERIC_L10N.CANCEL_BTN]}{' '}
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    renderTemplateItemsHeader = templateData => {
        const { localization, commonLocalization } = this.props;
        const { isEditMode, totalQty } = this.state;
        const { nonDeletedItemCount } = templateData;

        const addIcon = (
            <Icon className={cx('addIcon')} width='17px' height='16px' viewBox='0 0 17 16' name='addCart-Icon' />
        );
        return (
            <div className={cx('itemInfoHeader')}>
                <div className={cx('col8', 'sm12')}>
                    <h3>
                        {localization[TEMP_DETAILS_L10N.ITEMS]}{' '}
                        <span>
                            {localization[TEMP_DETAILS_L10N.TOTAL_ITEMS]}{' '}
                            <span>({templateData.qty ? templateData.qty : 0})</span>
                        </span>
                    </h3>
                </div>
                <div className={cx('col4', 'sm12', 'hiddenSm', 'rightBtn')}>
                    {isEditMode ? (
                        nonDeletedItemCount > 0 && (
                            <Button
                                automationId='at-remove-all-button'
                                className={cx('removeall')}
                                type='button'
                                buttonType='Ordinary'
                                size='Sm'
                                onClick={() => this.removeAllItems()}
                            >
                                {localization[TEMP_DETAILS_L10N.REMOVE_ALL_BTN]}{' '}
                            </Button>
                        )
                    ) : (
                        <Button
                            automationId='at-addall-to-Cart-button'
                            className={cx('orderaddtoCart')}
                            type='button'
                            buttonType='Primary'
                            size='Sm'
                            onClick={e => this.handleRestoreTemplate(e)}
                            isDisabled={!totalQty}
                        >
                            {localization[TEMP_DETAILS_L10N.ADD_ALL_CART_BTN]}
                            <span className={cx('addtoCardIcon')}>{addIcon}</span>
                        </Button>
                    )}
                </div>
            </div>
        );
    };

    setPrintComponentRef = ref => {
        this.componentPrintRef = ref;
    };

    render() {
        const { theme, orderTemplateData, localization, commonLocalization } = this.props;
        const { restoreModalData, copyModalData, isEditMode, totalQty } = this.state;
        const templateData = orderTemplateData;
        const { nonDeletedItemCount } = templateData;
        const addIcon = (
            <Icon className={cx('addIcon')} width='17px' height='16px' viewBox='0 0 17 16' name='addCart-Icon' />
        );

        return (
            <Layout showDashboardHeader showOutlet pageId={PAGE_ID.TEMPLATE_DETAILS}>
                <div
                    ref={this.setPrintComponentRef}
                    automation-id='at-template-details-screen'
                    className={`${cx('templateDetailsPage')} ${theme}`}
                >
                    <BreadCrumbs breadcrumbValues={orderTemplateData.breadcrumbs} />
                    {!isEmpty(templateData) && !isEmpty(localization) && (
                        <div className={`${cx('templateDetailsContainer')}`}>
                            <div className={cx('tempDetailsinfo', 'clearfix')}>
                                <h2 automation-id='header-section' className={cx('col8', 'sm12')}>
                                    <Icon width='32' height='32' viewBox='0 0 32 32' name='search-order' />
                                    {localization[TEMP_DETAILS_L10N.TITLE]}
                                </h2>

                                {this.renderPrintRestoreCopyButtons(templateData)}
                                {this.renderEditTemplateButtons()}
                                <div automation-id='template-details-form'>
                                    <div className={cx('col8', 'sm12', 'leftform')}>
                                        <TemplateDetailsForm
                                            orderTemplateData={templateData}
                                            onChangeName={this.handleChangeName}
                                            onChangeDesc={this.handleChangeDesc}
                                            isEditMode={isEditMode}
                                            localization={{
                                                name: localization[TEMP_DETAILS_L10N.NAME],
                                                dateSaved: localization[TEMP_DETAILS_L10N.DATE_SAVED],
                                                sku: localization[TEMP_DETAILS_L10N.NO_OF_UNIQUE_SKUS],
                                                description: localization[TEMP_DETAILS_L10N.DESC]
                                            }}
                                        />
                                        <div className={cx('visibleSm', 'mobileBtns')}>
                                            {isEditMode ? (
                                                nonDeletedItemCount > 0 && (
                                                    <Button
                                                        automationId='at-remove-all-button'
                                                        className={cx('removeall')}
                                                        type='button'
                                                        buttonType='Ordinary'
                                                        size='Sm'
                                                        onClick={() => this.removeAllItems()}
                                                    >
                                                        {localization[TEMP_DETAILS_L10N.REMOVE_ALL_BTN]}{' '}
                                                    </Button>
                                                )
                                            ) : (
                                                <span>
                                                    <Button
                                                        automationId='at-edit-option'
                                                        type='button'
                                                        buttonType='Ordinary'
                                                        size='Sm'
                                                        onClick={e => this.setToEditMode(e)}
                                                        className={cx('editTemplateBtn')}
                                                    >
                                                        {localization[TEMP_DETAILS_L10N.EDIT_TEMP_BTN]}
                                                    </Button>
                                                    <Button
                                                        automationId='at-addall-to-Cart-button'
                                                        className={cx('orderaddtoCartMobile')}
                                                        type='button'
                                                        buttonType='Primary'
                                                        size='Sm'
                                                        onClick={e => this.handleRestoreTemplate(e)}
                                                        isDisabled={!totalQty}
                                                    >
                                                        {localization[TEMP_DETAILS_L10N.ADD_ALL_CART_BTN]}{' '}
                                                        <span className={cx('addtoCardIcon')}>{addIcon}</span>
                                                    </Button>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!isEmpty(templateData.items) && (
                                <div
                                    automation-id='template-details-items'
                                    className={cx('tempDetailsItemInfo', 'clearfix')}
                                >
                                    {this.renderTemplateItemsHeader(templateData)}

                                    {templateData && templateData.items && (
                                        <ul className={cx('clearfix')}>
                                            {templateData.items.map((item, indexKey) => {
                                                if (isEditMode && item.deleted) return null;
                                                return (
                                                    <TemplateDetailsOrderItem
                                                        arrIndex={indexKey}
                                                        key={`item-${item.id}`}
                                                        templateId={templateData.id}
                                                        itemData={item}
                                                        onRemoveItem={this.handleRemoveItem}
                                                        onChangeQtyInput={this.handleChangeQtyInput}
                                                        onUpdateQty={this.handleUpdateQty}
                                                        onAddToCart={this.addToCartItem}
                                                        onOrderSequenceChange={this.handleSequenceChange}
                                                        isEditMode={isEditMode}
                                                        localization={{
                                                            addBtn: commonLocalization[GENERIC_L10N.ADD_TO_CART],
                                                            removeBtn: localization[TEMP_DETAILS_L10N.REMOVE_BTN],
                                                            deleteBtn: localization[TEMP_DETAILS_L10N.DELETE_BTN]
                                                        }}
                                                    />
                                                );
                                            })}
                                        </ul>
                                    )}

                                    <div className={cx('backtoTemp', 'clearfix')}>
                                        {!isEditMode ? (
                                            <Button
                                                automationId='at-addall-to-Cart-button'
                                                className={cx('orderaddtoCart')}
                                                type='button'
                                                buttonType='Primary'
                                                size='Sm'
                                                onClick={e => this.handleRestoreTemplate(e)}
                                                isDisabled={!totalQty}
                                            >
                                                {localization[TEMP_DETAILS_L10N.ADD_ALL_CART_BTN]}{' '}
                                                <span className={cx('addtoCardIcon')}>{addIcon}</span>
                                            </Button>
                                        ) : (
                                            nonDeletedItemCount > 0 && (
                                                <Button
                                                    automationId='at-remove-all-button'
                                                    className={cx('removeall')}
                                                    type='button'
                                                    buttonType='Ordinary'
                                                    size='Sm'
                                                    onClick={() => this.removeAllItems()}
                                                >
                                                    {localization[TEMP_DETAILS_L10N.REMOVE_ALL_BTN]}{' '}
                                                </Button>
                                            )
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {restoreModalData && (
                        <Modal
                            automationId='at-restore-template-popup'
                            showModal={Boolean(restoreModalData)}
                            onClose={this.handleRestoreModalClose}
                        >
                            <RestoreModalContent
                                row={restoreModalData}
                                onCancel={this.handleRestoreModalClose}
                                fromTemplateDetails={Boolean(true)}
                            />
                        </Modal>
                    )}
                    {copyModalData && (
                        <Modal
                            automationId='at-create-new-template-popup'
                            showModal={Boolean(copyModalData)}
                            onClose={e => {
                                this.handleCopyModalClose(e);
                                buttonClickEvent(
                                    buttonClickEventConfigs.templateDetails.actions.copy,
                                    buttonClickEventConfigs.templateDetails.labels.copyExit,
                                    buttonClickEventConfigs.templateDetails.categories.templateDetails
                                );
                            }}
                        >
                            <CreateNewTemplate
                                copyTemplateData={copyModalData}
                                onCancel={this.handleCopyModalClose}
                                fromTemplateDetails={Boolean(true)}
                                code={templateData.id}
                            />
                        </Modal>
                    )}
                </div>
            </Layout>
        );
    }
}

OrderTemplateDetails.propTypes = {
    orderTemplateData: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    localization: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

OrderTemplateDetails.defaultProps = {
    orderTemplateData: {},
    localization: {},
    commonLocalization: {}
};

const mapStateToProps = state => {
    return {
        orderTemplateData: _get(state, 'orderTemplateDetails'),
        localization: _get(state, 'pageContent.localization'),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getTemplateDetails,
            updateTemplateName,
            updateTemplateDescription,
            updateOrderTemplate,
            cancelOrderTemplate,
            updateOrderTemplateItemQty,
            updateOrderTemplateQty,
            deleteOrderTemplateItem,
            getPageContent,
            clearTemplateMessage,
            resetTemplateDetails,
            addToCart,
            setZeroQtyError,
            updateItemSequence,
            updateSortingOrderItem
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderTemplateDetails);
