import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import RadioButton from '../../core/RadioButton/RadioButton';
import ScrollToTop from '../../core/ScrollToTop/ScrollToTop';
import Button from '../../core/Button/Button';
import Input from '../../core/Input/Input';
import { USER_MAIN_L10N, URL_PATHS, PAGE_ID, GENERIC_L10N } from '../../../common/Constants';
import { emailRegExp, redirectTo } from '../../../common/Utils/Utils';
import { onAddNewUser, onEditUser } from '../../../actions/UserMaintenanceAction';
import * as styles from './UserMaintenanceForm.css';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

export class UserMaintenanceForm extends Component {
    static propTypes = {
        customClass: PropTypes.bool,
        pageTitle: PropTypes.string,
        selectedEditUserData: PropTypes.objectOf(PropTypes.any),
        localization: PropTypes.objectOf(PropTypes.string),
        buttonSave: PropTypes.bool,
        buttonRegister: PropTypes.bool,
        disabledField: PropTypes.bool,
        handleCancel: PropTypes.func,
        type: PropTypes.string,
        commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        automationId: PropTypes.string
    };

    static defaultProps = {
        customClass: false,
        disabledField: false,
        pageTitle: '',
        selectedEditUserData: {},
        localization: {},
        buttonSave: false,
        buttonRegister: false,
        handleCancel: () => {},
        commonLocalization: {},
        automationId: ''
    };

    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.firstTime = true;
        this.state = {
            emailErrorText: '',
            mobileErrorText: '',
            formValidationState: {
                firstNameErrorText: false,
                lastNameErrorText: false,
                mobileErrorText: false,
                emailErrorText: false
            }
        };
    }

    componentDidMount() {
        window.onpopstate = e => {
            if (this.firstTime) {
                e.preventDefault();
                this.onBackButtonEvent(e);
                this.firstTime = false;
            }
        };
        this.props.initalRadioButtonChange();
    }

    componentDidUpdate() {
        if (this.props.editSuccess) {
            this.props.handleCancel();
        }
    }

    onBackButtonEvent = () => {
        redirectTo(URL_PATHS.USER_MAINTENANCE);
        this.props.handleCancel();
    };

    onChangeHandler = event => {
        const formValidationState = {};
        const { selectedEditUserData } = this.props;
        this.props.onChangeHandler(event, this.props.type);
        if (_get(selectedEditUserData, 'email', '').length > 0) {
            formValidationState.emailErrorText = false;
        }
        if (_get(selectedEditUserData, 'firstName', '').length > 0) {
            formValidationState.firstNameErrorText = false;
        }
        if (_get(selectedEditUserData, 'lastName', '').length > 0) {
            formValidationState.lastNameErrorText = false;
        }
        if (_get(selectedEditUserData, 'mobile', '').length > 0) {
            formValidationState.mobileErrorText = false;
        }
        this.setState(formValidationState);
    };

    payloadAddData = () => {
        const { selectedEditUserData } = this.props;
        const userAddInfo = {
            uname: selectedEditUserData.userAlias,
            fname: selectedEditUserData.firstName,
            lname: selectedEditUserData.lastName,
            phone: selectedEditUserData.phone,
            mobile: selectedEditUserData.mobile,
            email: selectedEditUserData.email,
            role: this.props.statusValue
        };
        return userAddInfo;
    };

    payloadEditData = () => {
        const { selectedEditUserData } = this.props;
        const userEditInfo = {
            uname: selectedEditUserData.userAlias,
            phone: selectedEditUserData.phone,
            mobile: selectedEditUserData.mobile,
            email: selectedEditUserData.email,
            role: this.props.statusValue,
            userId: selectedEditUserData.userId
        };
        return userEditInfo;
    };

    onSaveChanges = () => {
        if (this.validateSaveForm()) {
            this.props.actions.onEditUser({ params: this.payloadEditData() });
        }
    };

    validateSaveForm = () => {
        const formValidationState = {};
        const { selectedEditUserData, localization } = this.props;
        const { email } = selectedEditUserData;
        let register = true;

        if (!this.validateEmail(email)) {
            formValidationState.emailErrorText = localization[USER_MAIN_L10N.EMAIL_ERROR_MSG];
            register = false;
        }
        this.setState(formValidationState);
        return register;
    };

    onRegisterChanges = () => {
        if (this.validateForm()) {
            this.props.actions.onAddNewUser({ params: this.payloadAddData() });
            this.props.handleCancel();
        }
    };

    validateForm = () => {
        const formValidationState = {};
        const { selectedEditUserData, localization } = this.props;
        const { firstName, lastName, mobile, email } = selectedEditUserData;
        let register = true;
        if (!firstName) {
            formValidationState.firstNameErrorText = 'error';
            register = false;
        }
        if (!lastName) {
            formValidationState.lastNameErrorText = 'error';
            register = false;
        }
        if (!mobile) {
            formValidationState.mobileErrorText = 'error';
            register = false;
        }

        if (!this.validateEmail(email)) {
            formValidationState.emailErrorText = localization[USER_MAIN_L10N.EMAIL_ERROR_MSG];
            register = false;
        }

        this.setState(formValidationState);
        return register;
    };

    validateEmail = value => {
        if (!value || !emailRegExp.test(value)) {
            return false;
        }
        return true;
    };

    renderRadioButton = (name, value, automationId) => {
        return (
            <RadioButton
                theme={cx('customRadio')}
                name='role'
                labelText={name}
                customCheckMark={cx('checkmark')}
                value={name}
                checked={value === this.props.statusValue}
                onChange={this.props.handleRadioButton}
                automationId={automationId}
            />
        );
    };

    renderRadioWrapper = (subTitle, radioName, radioValue, customClass, automationId) => {
        return (
            <div className={cx('col4', 'md6', 'sm12', 'mbrder')}>
                <h5 className={cx('tableTitle', 'subTitle', 'sm12', 'radioTitle', customClass)}>{subTitle}</h5>
                <span className={cx('cartval', 'sm12')}>
                    {this.renderRadioButton(radioName, radioValue, automationId)}
                </span>
            </div>
        );
    };

    renderCompanyData = () => {
        const { buttonSave, buttonRegister, handleCancel, localization, registered, saveEnable, commonLocalization } =
            this.props;
        const { companyName } = this.props;
        return (
            <div className={cx('confirmwidgetInfo', 'clearfix')} automation-id='at-company-name-section'>
                <ScrollToTop />
                <div className={cx('confirmwidgetInforows', 'clearfix')}>
                    {this.renderUserInfoContent(
                        localization[USER_MAIN_L10N.COMPANY_NAME],
                        'cellContentrow',
                        companyName
                    )}
                    <div className={cx('col9', 'sm12', 'widgetInforight')}>
                        <div
                            className={cx('confirmwidgetInforows', 'clearfix', 'widgetlastrow')}
                            automation-id='at-role-section'
                        >
                            {this.renderRadioWrapper(
                                localization[USER_MAIN_L10N.USER_ROLE],
                                localization[USER_MAIN_L10N.OWNER],
                                localization[USER_MAIN_L10N.OWNER],
                                'ownerClass',
                                'at-owner-radio-button'
                            )}
                            {this.renderRadioWrapper(
                                '',
                                localization[USER_MAIN_L10N.PAYER],
                                localization[USER_MAIN_L10N.PAYER],
                                'payerClass',
                                'at-payer-radio-button'
                            )}
                            {this.renderRadioWrapper(
                                '',
                                localization[USER_MAIN_L10N.ADMIN],
                                localization[USER_MAIN_L10N.ADMIN],
                                'adminClass',
                                'at-admin-radio-button'
                            )}
                            {this.renderRadioWrapper(
                                '',
                                localization[USER_MAIN_L10N.BUYER],
                                localization[USER_MAIN_L10N.BUYER],
                                'customClassTitle',
                                'at-buyer-radio-button'
                            )}
                        </div>

                        <div className={cx('buttonSection')}>
                            <Button
                                automationId='at-cancel-button'
                                className={cx('cancelButton', 'float-left')}
                                type='button'
                                size='Sm'
                                buttonType='Default'
                                onClick={e => {
                                    buttonClickEvent(
                                        buttonClickEventConfigs.userManagement.actions.userManagementAddNewUser,
                                        buttonClickEventConfigs.userManagement.labels.userManagementAddNewUserCangel,
                                        buttonClickEventConfigs.userManagement.categories.userManagement
                                    );
                                    handleCancel(e);
                                }}
                            >
                                {commonLocalization[GENERIC_L10N.CANCEL_BTN]}
                            </Button>
                            {buttonRegister && (
                                <Button
                                    automationId='at-register-button'
                                    className={cx('regButton', 'float-right', 'registerBtn')}
                                    type='button'
                                    size='Sm'
                                    buttonType='Primary'
                                    isDisabled={registered}
                                    onClick={e => {
                                        buttonClickEvent(
                                            buttonClickEventConfigs.userManagement.actions.userManagementAddNewUser,
                                            buttonClickEventConfigs.userManagement.labels
                                                .userManagementAddNewUserRegister,
                                            buttonClickEventConfigs.userManagement.categories.userManagement
                                        );
                                        this.onRegisterChanges(e);
                                    }}
                                >
                                    {localization[USER_MAIN_L10N.REGISTER]}
                                </Button>
                            )}
                            {buttonSave && (
                                <Button
                                    automationId='at-save-button'
                                    className={cx('regButton', 'float-right', 'saveBtn')}
                                    type='button'
                                    size='Sm'
                                    buttonType='Primary'
                                    isDisabled={!saveEnable}
                                    onClick={e => this.onSaveChanges(e)}
                                >
                                    {commonLocalization[GENERIC_L10N.SAVE_BTN]}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderUserInfoContent = (value, styleName, subTitle) => {
        return (
            <div
                className={cx('col3', 'sm12', 'widgetInfoleft', 'mbrder', styleName)}
                automation-id='at-company-name-text'
            >
                <h5 className={cx('tableTitle', 'sm6')}>{value}</h5>
                {subTitle && <p className={cx('subTitle', 'sm6', 'companyTitle')}>{subTitle}</p>}
            </div>
        );
    };

    renderRgtContentRow = (title, automationId, id, name, placeholder, disabledField = '') => {
        const { selectedEditUserData } = this.props;
        const { emailErrorText, formValidationState, firstNameErrorText, mobileErrorText } = this.state;
        const emptyInput =
            formValidationState &&
            ((id === 'FirstName' && !_isEmpty(firstNameErrorText) && 'error') ||
                (id === 'LastName' && !_isEmpty(firstNameErrorText) && 'error') ||
                (id === 'Mobile' && !_isEmpty(mobileErrorText) && 'error') ||
                (id === 'Email' && !_isEmpty(emailErrorText) && 'error'));
        return (
            <div className={cx('col4', 'md6', 'sm12', 'mbrder', disabledField && 'disabledData')}>
                <h5 className={cx('tableTitle', 'sm12')}>{title}</h5>
                <span className={cx('cartval', 'sm12')}>
                    <Input
                        automationId={automationId}
                        id={id}
                        type='text'
                        onChange={this.onChangeHandler}
                        name={name}
                        value={selectedEditUserData[name] || ''}
                        defaultValue={selectedEditUserData[name] || ''}
                        placeholder={placeholder}
                        theme={emptyInput}
                    />
                </span>
                {id === 'Email' && emailErrorText && (
                    <p className={cx('errMsg')} automation-id='at-invalid-email-error-message'>
                        {emailErrorText}
                    </p>
                )}
            </div>
        );
    };

    renderMainContent = () => {
        const { disabledField, localization } = this.props;
        return (
            <div className={cx('confirmwidgetInfo', 'clearfix')}>
                <div className={cx('confirmwidgetInforows', 'clearfix')}>
                    <p className={cx('requiedField')}>{localization[USER_MAIN_L10N.MANDATORY_INFO]}</p>
                    {this.renderUserInfoContent(localization[USER_MAIN_L10N.USER_INFORMATION], 'titleName', '')}
                    <div className={cx('col9', 'sm12', 'widgetInforight', 'firstData')}>
                        <div className={cx('confirmwidgetInforows', 'clearfix')}>
                            {this.renderRgtContentRow(
                                localization[USER_MAIN_L10N.USER_NAME],
                                'at-username-textfield',
                                'UserName',
                                'userAlias',
                                localization[USER_MAIN_L10N.PLACE_HOLDER_USER_NAME]
                            )}
                            {this.renderRgtContentRow(
                                localization[USER_MAIN_L10N.FIRST_NAME],
                                'at-firstname-textfield',
                                'FirstName',
                                'firstName',
                                localization[USER_MAIN_L10N.PLACE_HOLDER_FIRST_NAME],
                                disabledField
                            )}
                            {this.renderRgtContentRow(
                                localization[USER_MAIN_L10N.LAST_NAME],
                                'at-lastname-textfield',
                                'LastName',
                                'lastName',
                                localization[USER_MAIN_L10N.PLACE_HOLDER_LAST_NAME],
                                disabledField
                            )}
                        </div>

                        <div className={cx('confirmwidgetInforows', 'clearfix')}>
                            {this.renderRgtContentRow(
                                localization[USER_MAIN_L10N.PHONE],
                                'at-phone-textfield',
                                'Phone',
                                'phone',
                                localization[USER_MAIN_L10N.PLACE_HOLDER_PHONE]
                            )}
                            {this.renderRgtContentRow(
                                localization[USER_MAIN_L10N.MOBILE],
                                'at-mobile-textfield',
                                'Mobile',
                                'mobile',
                                localization[USER_MAIN_L10N.PLACE_HOLDER_MOBILE]
                            )}
                            {this.renderRgtContentRow(
                                localization[USER_MAIN_L10N.EMAIL],
                                'at-email-textfield',
                                'Email',
                                'email',
                                localization[USER_MAIN_L10N.PLACE_HOLDER_EMAIL]
                            )}
                        </div>
                    </div>
                </div>
                <div className={cx('col3', 'sm12', 'widgetInfoleft', 'hiddenSm')} />
            </div>
        );
    };

    render() {
        const { customClass, pageTitle, automationId, pageTitleId } = this.props;
        return (
            <div className={cx('userMaintenanceForm', customClass)} automation-id={automationId}>
                <div className={cx('commonWidget', 'clearfix')}>
                    <h2>
                        <span className={cx('pageTitle')} automation-id={pageTitleId}>
                            {pageTitle}
                        </span>
                    </h2>
                    {this.renderMainContent()}
                    {this.renderCompanyData()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        localization: _get(state, 'pageContent.localization'),
        deviceType: _get(state, 'context.deviceType'),
        editSuccess: _get(state, 'userMaintenanceDetails.editSuccess') || false,
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            onAddNewUser,
            onEditUser
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMaintenanceForm);
