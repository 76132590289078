import React, { useState } from 'react';

import _get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { PAGE_ID, TEMP_L10N, GENERIC_L10N, PAGINATION_L10N } from '../../../../common/Constants';
import { getPageContent } from '../../../../actions/PageContentAction';

import classNames from 'classnames/bind';
import * as styles from '../../OrderTemplate/OrderTemplate.css';
import Button from '../../../../components/core/Button/Button';
import Modal from '../../../../components/core/Modal/Modal';
import Icon from '../../../../components/core/Icon/Icon';

import Pagination from '../../../../components/core/Pagination/Pagination';
import RestoreModalContent from '../../../../components/site/RestoreModalContent/RestoreModalContent';
import DeleteTemplateModalContent from '../../../../components/site/DeleteTemplateModalContent/DeleteTemplateModalContent';
import { getOrderTemplateDetails, resetExcelErrors } from '../../../../actions/OrderTemplateAction';
import isEmpty from 'lodash/isEmpty';
import { CreateTemplate } from './CreateTemplate';
import { ListOfTemplates } from './ListOfTemplates';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../../common/GoogleTagManager/GoogleTagManager';
const cx = classNames.bind(styles);
export const AccountShoppingSavedCart = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(false);
    const [copyModalData, setCopyModalData] = useState(null);
    const [deleteModalData, setDeleteModalData] = useState(false);

    const localization = useSelector(state => state.pageContent.localization);
    const orderTemplate = useSelector(state => state.orderTemplate.templateList);
    const templateUploadError = useSelector(state => state.orderTemplate.templateUploadError);

    const commonLocalization = useSelector(state => state.pageContent.commonLocalization[PAGE_ID.GENERIC]);
    const dispatch = useDispatch();

    const pagination = orderTemplate?.pagination;
    const carts = orderTemplate?.carts;
    const currentPage = pagination?.currentPage || 0;

    const OnClose = () => {
        setCopyModalData(null);
        setShowModal(false);
    };

    const handleCreateNewTemplate = () => {
        setCopyModalData(null);
        setShowModal(true);
        buttonClickEvent(
            buttonClickEventConfigs.SLorderTemplates.actions.createNew,
            buttonClickEventConfigs.SLorderTemplates.labels.createNewCreate,
            buttonClickEventConfigs.SLorderTemplates.categories.orderTemplates
        );
    };

    const handleRestoreClick = row => {
        const selectedTemp = {
            name: row.name,
            id: row.code,
            qty: row.totalItems,
            totalQty: 0,
            products: []
        };

        if (row.entries && row.entries.length) {
            for (let i = 0; i < row.entries.length; i += 1) {
                if (row.entries[i].quantity > 0)
                    selectedTemp.products.push({
                        productCode: row.entries[i].product.code,
                        quantity: `${row.entries[i].quantity}`,
                        unitCode: row.entries[i].qtyType
                    });
            }

            selectedTemp.totalQty = row.entries.reduce((total, obj) => total + obj.quantity, 0);
        }
        setModalData(selectedTemp);
    };

    const modalOnClose = () => {
        setModalData(null);
    };

    const renderRestoreTemplate = row => {
        return (
            <Button
                automation-id='at-add-to-card-button'
                className={cx('restoreButton', 'iconAlignRestore')}
                type='button'
                buttonType='Primary'
                onClick={() => {
                    handleRestoreClick(row);
                    buttonClickEvent(
                        buttonClickEventConfigs.SLorderTemplates.actions.list,
                        buttonClickEventConfigs.SLorderTemplates.labels.listRestore,
                        buttonClickEventConfigs.SLorderTemplates.categories.orderTemplates
                    );
                }}
            >
                {localization[TEMP_L10N.RESTORE_BTN]}
            </Button>
        );
    };

    const handleCopy = item => {
        const copyData = {
            id: item.code,
            name: `Copy-${item.name}`
        };
        setCopyModalData(copyData);
        setShowModal(true);
    };

    const renderCopyTemplate = item => {
        return (
            <Button automation-id='at-copy-icon' className={cx('iconAlign')} onClick={() => handleCopy(item)}>
                <Icon iconType='svg' width='25' height='24' viewBox='0 0 25 24' name='copy' />
            </Button>
        );
    };

    const deleteModalOnClose = () => {
        setDeleteModalData(null);
    };

    const handleRemove = row => {
        setDeleteModalData(row);
    };

    const renderRemoveTemplate = row => {
        return (
            <Button
                automation-id='at-remove-icon'
                className={cx('removeButton')}
                onClick={() => {
                    handleRemove(row);
                    buttonClickEvent(
                        buttonClickEventConfigs.SLorderTemplates.actions.list,
                        buttonClickEventConfigs.SLorderTemplates.labels.listDelete,
                        buttonClickEventConfigs.SLorderTemplates.categories.orderTemplates
                    );
                }}
            >
                <Icon iconType='svg' width='25' height='24' viewBox='0 0 25 24' name='remove' />
            </Button>
        );
    };

    const onPaginationHandler = (type, seletedItem) => {
        if (type === 'page') {
            dispatch(getOrderTemplateDetails({ pageNumber: seletedItem - 1, pageSize: pagination.pageSize }));
        } else if (type === 'size') {
            dispatch(getOrderTemplateDetails({ pageNumber: 0, pageSize: seletedItem }));
        }
    };

    const getPageContentFunc = React.useCallback(() => {
        dispatch(getOrderTemplateDetails());
    }, [dispatch, getOrderTemplateDetails]);

    React.useEffect(() => {
        getPageContentFunc();
    }, [getPageContentFunc]);

    return (
        <div>
            {!isEmpty(orderTemplate) && !isEmpty(localization) && (
                <div automation-id='at-order-template-list' className={cx('orderTemp', 'clearfix', 'savedCart')}>
                    <CreateTemplate
                        pagination={pagination}
                        copyModalData={copyModalData}
                        showModal={showModal}
                        handleCreateNewTemplate={handleCreateNewTemplate}
                        OnClose={OnClose}
                    />
                    <div className={cx('clearfix')}>
                        {pagination && pagination.totalPages > 0 && (
                            <div className={cx('toppaginations', 'hiddenSm')}>
                                <Pagination
                                    automation-id='at-page-number-dropdown'
                                    currentPage={currentPage + 1}
                                    totalPage={pagination.totalPages}
                                    onChange={e => onPaginationHandler('page', e)}
                                    itemOnChange={e => onPaginationHandler('size', e)}
                                    currentItems={pagination.pageSize}
                                    totalItemsPage={pagination.totalResults}
                                    renderType='List'
                                    pageSize={pagination.pageSize}
                                    itemsOptions={pagination.itemsPerPage}
                                    themeSelectBox={cx('themeSelectBox')}
                                    arrowTheme={cx('arrowTheme', 'down')}
                                    paginationSelect={cx('paginationSelect')}
                                    customListPage={cx('customListPage')}
                                    pageText={commonLocalization[GENERIC_L10N.PAGE]}
                                    itemsText={commonLocalization[GENERIC_L10N.ITEMS]}
                                    ofText={localization[PAGINATION_L10N.OF]}
                                />
                            </div>
                        )}
                    </div>
                    <ListOfTemplates
                        pagination={pagination}
                        renderRestoreTemplate={renderRestoreTemplate}
                        renderCopyTemplate={renderCopyTemplate}
                        renderRemoveTemplate={renderRemoveTemplate}
                        carts={carts}
                    />

                    {templateUploadError && (
                        <Modal
                            automationId='at-restore-template-popup'
                            showModal
                            onClose={() => dispatch(resetExcelErrors)}
                            modalTheme={cx('userThemeModal', 'orderuserThemeModal')}
                        >
                            <OrderTemplateModal
                                content={templateUploadError}
                                onClose={() => dispatch(resetExcelErrors)}
                            />
                        </Modal>
                    )}
                    {modalData && (
                        <Modal
                            automationId='at-restore-template-popup'
                            showModal={Boolean(modalData)}
                            onClose={modalOnClose}
                            modalTheme={cx('userThemeModal', 'orderuserThemeModal')}
                        >
                            <RestoreModalContent
                                row={modalData}
                                onCancel={modalOnClose}
                                pageNumber={`${currentPage}`}
                                pageSize={`${pagination.pageSize}`}
                            />
                        </Modal>
                    )}
                    {deleteModalData && (
                        <Modal
                            automationId='at-delete-template-popup'
                            showModal={Boolean(deleteModalData)}
                            onClose={deleteModalOnClose}
                            modalTheme={cx('userThemeModal', 'orderuserThemeModal')}
                        >
                            <DeleteTemplateModalContent
                                row={deleteModalData}
                                onCancel={deleteModalOnClose}
                                pageNumber={`${currentPage}`}
                                pageSize={`${pagination.pageSize}`}
                            />
                        </Modal>
                    )}
                </div>
            )}
            {pagination && !isEmpty(localization) && pagination.totalPages > 0 && (
                <div className={cx('bottomcounts')}>
                    <div className={cx('pagger')}>
                        <Pagination
                            id='paginationId'
                            currentPage={currentPage + 1}
                            totalPage={pagination.totalPages}
                            onClick={e => onPaginationHandler('page', e)}
                            pageSize={pagination.pageSize}
                            itemsOptions={pagination.itemsPerPage}
                            paginationSelect={cx('paginationSelect')}
                            itemOnChange={e => onPaginationHandler('size', e)}
                            pageText={commonLocalization[GENERIC_L10N.PAGE]}
                            itemsText={commonLocalization[GENERIC_L10N.ITEMS]}
                            ofText={localization[PAGINATION_L10N.OF]}
                            arrowTheme={cx('arrowTheme')}
                        />
                    </div>
                    <span className={cx('hiddenSm', 'Items')}>
                        {pagination.totalResults} {localization[TEMP_L10N.NO_OF_ORDERS_TEXT]}
                    </span>
                </div>
            )}
        </div>
    );
};
