import {
    SET_CUTOFF_NOTIFICATION,
    SET_SUCCESS_NOTIFICATION,
    SET_BLUE_NOTIFICATION,
    SET_WARN_NOTIFICATION,
    SET_ERROR_NOTIFICATION,
    CLEAR_NOTIFICATION,
    CLEAR_ALL_NOTIFICATION,
    SET_MILTIPLE,
    PUSH_NOTIFICATIONS,
    CLEAR_SPECIFIC_NOTIFICATION
} from '../actionTypes/NotificationActionTypes';
import { MESSAGE_TYPES } from '../common/Constants';

const initialState = {
    messageList: [],
    cutOffNotifications: []
};

function getMessageList(action, type) {
    let messageList = [];

    if (action.data) {
        const { isAutoClose, autoCloseTime } = action.data;

        if (action.data.messages) {
            messageList = action.data.messages.map(msg => {
                const message = {
                    content: msg,
                    type,
                    isAutoClose,
                    autoCloseTime
                };
                return message;
            });
        }

        if (action.data.message) {
            messageList.push({
                content: action.data.message,
                type,
                isAutoClose,
                autoCloseTime
            });
        }
    }
    return messageList;
}

/**
 * TODO:
 * 1) Future enhancement to use respective color codes & its action methods from saga or anywhere.
 * 2) Currently the default message color is YELLOW so WARN type is used for success/error as well.
 * 3) Used SET_WARN_NOTIFICATION for displaying GREEN for ADD TO CART. Later can be swapped to SUCCESS action.
 */
export default function NotificationReducer(state = initialState, action = {}) {
    switch (action.type) {
        case PUSH_NOTIFICATIONS: {
            return {
                ...state,
                messageList: [
                    ...state.messageList,
                    ...action.data.messages
                ]
            }
        }
        case SET_MILTIPLE: {
            return {
                ...state,
                messageList: [
                    ...state.messageList.filter(msg => {
                        return !action.data.messages.some(msgObj => msgObj.content === msg.content)
                    }),
                    ...action.data.messages
                ].sort((a, b) => a.content.toLowerCase().localeCompare(b.content.toLowerCase()))
            }
        }
        case SET_CUTOFF_NOTIFICATION: {
            return {
                ...state,
                cutOffNotifications: (action.data || {}).messageList
            };
        }
        case SET_SUCCESS_NOTIFICATION: {
            return {
                ...state,
                messageList: getMessageList(action, MESSAGE_TYPES.SUCCESS)
            };
        }
        case SET_BLUE_NOTIFICATION: {
            return {
                ...state,
                messageList: getMessageList(action, MESSAGE_TYPES.BLUE_NOTIFICATION)
            };
        }
        case SET_WARN_NOTIFICATION: {
            return {
                ...state,
                messageList: getMessageList(action, MESSAGE_TYPES.WARN)
            };
        }
        case SET_ERROR_NOTIFICATION: {
            return {
                ...state,
                messageList: getMessageList(action, MESSAGE_TYPES.ERROR)
            };
        }
        case CLEAR_NOTIFICATION: {
            if (action.isCutOff) {
                const cutOffNotifications = [...state.cutOffNotifications];
                cutOffNotifications.splice(action.index, 1);

                return {
                    ...state,
                    cutOffNotifications
                };
            }
            const messageList = state.messageList.splice(action.index, 1);

            return {
                ...state,
                ...messageList
            };
        }
        case CLEAR_SPECIFIC_NOTIFICATION: {
            return {
                ...state,
                messageList: state.messageList.filter((msg) => msg.content !== action.data)
            };
        }
        case CLEAR_ALL_NOTIFICATION: {
            return {
                ...initialState,
                cutOffNotifications: state.cutOffNotifications
            };
        }
        default:
            return state;
    }
}
