import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createStateSyncMiddleware, initMessageListener, withReduxStateSync } from 'redux-state-sync';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import contextReducer from './ContextReducer';
import rootReducer from '../../src/reducers';
import rootSagas from '../../src/sagas';
import { getApplicationContext } from './ContextLoader';
import { ADD_TO_CART, GET_MULTI_CART, UPDATE_CART, UPDATE_SAP_CART, OPEN_MINI_CART } from '../../src/actionTypes/CartActionTypes';
import { GET_PAGE_CONTENT_REQUEST, GET_PAGE_CONTENT_SUCCESS } from '../../src/actionTypes/PageContentActionTypes';
import { POST_PLACE_ORDER_DATA_REQUEST } from '../../src/actionTypes/CheckoutActionTypes';
import { GET_PRE_CHECKOUT_UPDATE, ADD_TO_CART_PROMO } from '../../src/actionTypes/PromotionsActionTypes';
import { NEW_REGISTER_USER, USER_LOGIN } from '../../src/actionTypes/LoginActionTypes';
import { SUBMIT_CONTACT_INFO_FORM } from '../../src/actionTypes/ContactMeActionTypes';
import { CREATE_CLAIM_REQUEST, SUMBIT_CLAIM_REQUEST } from '../../src/actionTypes/InvoicesActionTypes';
import { UPDATE_ORDER_TEMPLATE_DETAILS_QUANTITY_REQUEST, UPDATE_ORDER_TEMPLATE_DETAILS_REQUEST } from '../../src/actionTypes/OrderTemplateDetailsActionTypes';
import { CREATE_ORDER_TEMPLATE_REQUEST, ADD_ITEM_TO_TEMPLATE } from '../../src/actionTypes/OrderTemplateActionTypes';
import { GET_PRODUCT_LIST_REQUEST, GET_PRODUCT_LIST_SUCCESS } from '../../src/actionTypes/ProductListActionTypes';

// Redux at the moment is set as a session storage and not local storage.
// When the user logs out of the app the url is set to the login path programatically
// therefore a clearing of the reducers is not needed. There are numerous updates
// to the storage for changing keys that trigger redirects and we don't have time to refactor
// the entire thing at the moment, so that app data is handled from a single place in a more manageable way.

const initialState = { context: getApplicationContext() };
// const persistConfig = {
//     key: 'root',
//     storage,
//     version: 1,
//     migrate: (state = {}) => {
//         return { ...state }
//     }
// }

const bootReducers = {
    context: contextReducer,
    ...rootReducer
};

// Events in blacklist will not trigger on all tabs (only current)
const syncConfig = {
    blacklist: [
        ADD_TO_CART,
        GET_PAGE_CONTENT_REQUEST,
        GET_PAGE_CONTENT_SUCCESS,
        POST_PLACE_ORDER_DATA_REQUEST,
        GET_PRE_CHECKOUT_UPDATE,
        OPEN_MINI_CART,
        GET_MULTI_CART,
        USER_LOGIN,
        NEW_REGISTER_USER,
        SUBMIT_CONTACT_INFO_FORM,
        CREATE_CLAIM_REQUEST,
        SUMBIT_CLAIM_REQUEST,
        CREATE_ORDER_TEMPLATE_REQUEST,
        ADD_ITEM_TO_TEMPLATE,
        UPDATE_ORDER_TEMPLATE_DETAILS_QUANTITY_REQUEST,
        UPDATE_ORDER_TEMPLATE_DETAILS_REQUEST,
        UPDATE_CART,
        UPDATE_SAP_CART,
        ADD_TO_CART_PROMO,
        GET_PRODUCT_LIST_REQUEST,
        GET_PRODUCT_LIST_SUCCESS
    ]
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({ trace: true, traceLimit: 25 }) || compose;

const enhancers = [];
const middleware = [];
const sagaMiddleware = createSagaMiddleware();
const syncMiddleware = createStateSyncMiddleware(syncConfig);
middleware.push(sagaMiddleware);
middleware.push(syncMiddleware);


// const appReducer = combineReducers({
//     context: contextReducer,
//     ...rootReducer
// })

// const reduxStore = (state, action) => {
//     if (action.type === 'USER_LOGOUT') {
//         storage.removeItem('persist:root')
//         return appReducer(undefined, action)
//     }
  
//     return appReducer(state, action)
// }

enhancers.push(applyMiddleware(...middleware));
// const persistedReducer =
//     persistReducer(
//         persistConfig,
//         withReduxStateSync(combineReducers(bootReducers))
//     );
export const store = createStore(
    // persistedReducer,
    combineReducers(bootReducers),
    initialState,
    composeEnhancers(...enhancers)
)

// export const persistor = persistStore(store)

initMessageListener(store);
sagaMiddleware.run(rootSagas);