import TagManager from 'react-gtm-module';
import _get from 'lodash/get';
import { getCustomerId, getUserId, getCurrency, getLocalization } from '../UserManager/UserManager';
import { LOGIN_INFO } from '../Constants';
import { assignProductListCurrency, assignProductListIDsGA4 } from '../../service/DataLayerFilter';
import { SessionStorage } from '../Storage/Storage';
import ReactGA4 from '../GoogleAnalytics/GA4';

(() => {
    let previousPath = window.location.pathname;

    function updateLastPath() {
        window.lastPath = previousPath;
        previousPath = window.location.pathname;
    }

    updateLastPath();

    const pushState = history.pushState;
    history.pushState = function (...args) {
        pushState.apply(history, args);
        updateLastPath();
    };

    const replaceState = history.replaceState;
    history.replaceState = function (...args) {
        replaceState.apply(history, args);
        updateLastPath();
    };

    window.addEventListener('popstate', updateLastPath);
})();

export const getGTMuserId = () => getCustomerId() || getUserId();

const gaMutableConfig = {
    localization: null,
    sapOutboundId: null,
    campaignId: null
};

const updateMutableLocalization = () => {
    gaMutableConfig.localization = getLocalization().siteId;
};

const updateMutableSapOutboundId = () => {
    const [newOutboundId, newCampaignId] = ['sap-outbound-id', 'utm_campaign'].map(
        p => window.location.href.match(new RegExp(`${p}=(\\w+)`))?.[1] || null
    );
    if (newOutboundId) {
        [gaMutableConfig.sapOutboundId, gaMutableConfig.campaignId] = [newOutboundId, newCampaignId];
        SessionStorage.setData('sapOutboundId', newOutboundId);
        SessionStorage.setData('campaignId', newCampaignId);
        return;
    }

    const [foundInStorageOutboundId, foundInStorageCampaignId] = ['sapOutboundId', 'campaignId'].map(p =>
        SessionStorage.getData(p)
    );
    if (foundInStorageOutboundId) {
        [gaMutableConfig.sapOutboundId, gaMutableConfig.campaignId] = [
            foundInStorageOutboundId,
            foundInStorageCampaignId
        ];
    }
};

export const getGaMutableConfig = () => {
    updateMutableLocalization();
    updateMutableSapOutboundId();
    return gaMutableConfig;
};

const getSapMarketingData = () => {
    const mutableGaConfig = getGaMutableConfig();
    const sapMarketingCountries = ['poland', 'russia', 'czech', 'hungary', 'ng'];
    return sapMarketingCountries.includes(mutableGaConfig.localization)
        ? { sapOutboundId: mutableGaConfig.sapOutboundId, campaignId: mutableGaConfig.campaignId }
        : {};
};

let ga4enabled = false;
const dataLayerVariables = {};
let gaConfig = {};
window.dataLayer = [];
export const initializeGTAG = state => {
    const siteId = getLocalization()[LOGIN_INFO.SITE_ID] || '';

    gaConfig = _get(state, 'context.environment.gaConfig', {});

    if (gaConfig[siteId] && gaConfig[siteId].gtgId) {
        const tagManagerArgs = {
            gtmId: gaConfig[siteId].gtgId,
            dataLayer: {
                userId: getGTMuserId(),
                marketingArea: gaConfig[siteId].marketingArea || ''
            }
        };

        TagManager.initialize(tagManagerArgs);

        ga4enabled = !!gaConfig[siteId]?.ga4Id;
        dataLayerVariables.userId = getGTMuserId();
        dataLayerVariables.marketingArea = gaConfig[siteId].marketingArea || '';
    }
};

const customDimensionsGA4 = [
    'search_term',
    'product_code',
    'marketingArea',
    'userId',
    'sapOutboundId',
    'campaignId',
    'event_category',
    'event_action',
    'event_label',
    'origin_element'
];
const customDimensions = [...['ecommerce'], ...customDimensionsGA4];
const customDimensionsClearObj = customDimensions.reduce((obj, prop) => Object.assign(obj, { [prop]: undefined }), {});

const pushDataLayerEventData = (mainEventData = {}) => {
    const eventData = { ...dataLayerVariables, ...getSapMarketingData(), ...mainEventData };
    window.dataLayer.push({ ...customDimensionsClearObj });
    window.dataLayer.push(eventData);
};

export const viewItemListEventGA4 = (productImpressionsListData, searchTerm) => {
    if (!ga4enabled) return;

    const impressions = assignProductListCurrency(assignProductListIDsGA4(productImpressionsListData));

    const viewItemListEventData = {
        event: 'view_item_list',
        search_term: searchTerm || '',
        ecommerce: {
            items: impressions
        }
    };

    pushDataLayerEventData(viewItemListEventData);
};

export const viewItemEventGA4 = productDetailData => {
    if (!ga4enabled) return;

    const products = assignProductListCurrency(assignProductListIDsGA4(productDetailData));

    const viewItemEventData = {
        event: 'view_item',
        ecommerce: {
            items: products
        }
    };

    pushDataLayerEventData(viewItemEventData);
};

export const addToCartEventGA4 = (productDetailData, originElement) => {
    if (!ga4enabled) return;

    if (!productDetailData || (productDetailData?.length === 1 && productDetailData[0].quantity === 0)) {
        return;
    }

    const products = assignProductListCurrency(assignProductListIDsGA4(productDetailData));

    const addItemEventData = {
        event: 'add_to_cart',
        origin_element: originElement,
        ecommerce: {
            items: products
        }
    };

    pushDataLayerEventData(addItemEventData);
};

export const removeFromCartEventGA4 = (productDetailData, originElement) => {
    if (!ga4enabled) return;

    if (!productDetailData?.length) return;

    const products = assignProductListCurrency(assignProductListIDsGA4(productDetailData));

    const removeItemEventData = {
        event: 'remove_from_cart',
        origin_element: originElement,
        ecommerce: {
            items: products
        }
    };

    pushDataLayerEventData(removeItemEventData);
};

export const productClickEventGA4 = (productDetailData, listName) => {
    if (!ga4enabled) return;

    const products = assignProductListCurrency(assignProductListIDsGA4(productDetailData));

    products.forEach(p => {
        p.item_list_id = listName || '';
        p.item_list_name = listName || '';
        p.quantity = p.quantity || 1;
    });

    const productClickEvenData = {
        event: 'select_item',
        ecommerce: {
            items: products
        }
    };

    pushDataLayerEventData(productClickEvenData);
};

export const addToFavoritesEventGA4 = productCode => {
    if (!ga4enabled) return;

    const addToFavoritesEventData = {
        event: 'add_to_favorites',
        product_code: productCode.substring(22)
    };

    pushDataLayerEventData(addToFavoritesEventData);
};

export const removeFromFavoritesEventGA4 = productCode => {
    if (!ga4enabled) return;

    const removeFromFavoritesEventData = {
        event: 'remove_from_favorites',
        product_code: productCode.substring(22)
    };

    pushDataLayerEventData(removeFromFavoritesEventData);
};

export const checkoutEventGA4 = cartProducts => {
    if (!ga4enabled) return;

    const products = assignProductListCurrency(assignProductListIDsGA4(cartProducts));

    const checkoutData = {
        event: 'begin_checkout',
        ecommerce: {
            items: products
        }
    };

    pushDataLayerEventData(checkoutData);
};

export const purchaseEvent = (cartProductsGA4, transactionId, affiliation, revenue, tax, shipping, coupon) => {
    if (!ga4enabled) {
        return;
    }

    const items = assignProductListCurrency(assignProductListIDsGA4(cartProductsGA4));

    const purchaseData = {
        event: 'purchase',
        ecommerce: {
            transaction_id: transactionId,
            affiliation,
            value: revenue,
            tax,
            shipping,
            currency: getCurrency().isocode,
            coupon,
            items
        }
    };

    pushDataLayerEventData(purchaseData);
};

export const promotionImpressionEventGA4 = promotions => {
    if (!ga4enabled) return;

    const promotionImpressionEventData = {
        event: 'view_promotion',
        ecommerce: {
            items: promotions
        }
    };

    pushDataLayerEventData(promotionImpressionEventData);
};

export const promotionClickEventGA4 = promotions => {
    if (!ga4enabled) return;

    const promotionClickEventData = {
        event: 'select_promotion',
        ecommerce: {
            items: promotions
        }
    };

    pushDataLayerEventData(promotionClickEventData);
};

export const userLoggedInEvent = () => {
    if (!ga4enabled) {
        return;
    }

    const USER_ID = getGTMuserId();
    if (!USER_ID) {
        delete dataLayerVariables.userId;
        return;
    }

    const userLoggedInEventData = {
        event: 'login',
        userId: USER_ID
    };

    ReactGA4.set({ userId: USER_ID });
    dataLayerVariables.user_id = USER_ID; // ga4 session
    dataLayerVariables.userId = USER_ID; // data layer
    pushDataLayerEventData(userLoggedInEventData);
};

export const searchEvent = (search_term = '') => {
    if (!ga4enabled) {
        return;
    }

    if (!search_term) return;

    const searchEventData = {
        event: 'search',
        search_term,
        ecommerce: {
            search_term
        }
    };

    pushDataLayerEventData(searchEventData);
};

export const buttonClickEventConfigs = {
    preLogin: {
        categories: {
            preLogin: 'Pre Login'
        },
        actions: {
            preLoginHeader: 'Pre Login - Header',
            preLoginThirdBanner: 'Pre Login - Third Banner',
            preLoginCallUsPopUp: 'Pre Login - Call Us Pop Up',
            preLoginFAQ: 'Pre Login - FAQ',
            preLoginFooter: 'Pre Login - Footer',
            preLoginBottomBanner: 'Pre Login - Bottom Banner',
            preLoginFirstBanner: 'Pre Login - First Banner',
            preLoginFourthBanner: 'Pre Login - Fourth Banner',
            preLoginCountryLanguagePopUp: 'Pre Login - Country Language Pop-Up',
            preLoginCallUs: 'Pre Login - Call Us',
            preLoginSignUpPopUp: 'Pre Login - Sign up Pop Up'
        },
        labels: {
            preLoginHeaderAboutUsWhyCCHBC: 'Pre Login - Header - About Us Why CCHBC', // done | tested
            preLoginHeaderAboutUsWhyCommitment: 'Pre Login - Header - About Us Why Commitment', // done | tested
            preLoginHeaderBenefits: 'Pre Login - Header - Benefits', // done | tested
            preLoginHeaderHowItWorks: 'Pre Login - Header - How It Works', // done | tested
            preLoginHeaderCountryLanguageChoice: 'Pre Login - Header - Country Language Choice', // done | tested
            preLoginHeaderLogin: 'Pre Login - Header - Login', // done | tested
            preLoginHeaderSignUp: 'Pre Login - Header - Sign up', // done | tested
            preLoginCallUsPopUpCancel: 'Pre Login - Call Us Pop Up - Cancel', // done | tested
            preLoginCallUsPopUpCallUs: 'Pre Login - Call Us Pop Up - Call Us', // done | tested
            preLoginFAQScroll: 'Pre Login - FAQ - Scroll', // done | tested
            preLoginFooterImprint: 'Pre Login - Footer - Imprint', // done | tested
            preLoginFooterOurCompany: 'Pre Login - Footer - Our Company', // done | tested
            preLoginFooterPrivacyCookiePolicy: 'Pre Login - Footer - Privacy & Cookie Policy', // done | tested
            preLoginBottomBannerLogin: 'Pre Login - Bottom Banner - Login', // done | tested
            preLoginBottomBannerSignUp: 'Pre Login - Bottom Banner - Sign up', // done | tested
            preLoginBottomBannerCallUs: 'Pre Login - Bottom Banner - Call Us', // done | tested
            preLoginFirstBannerLogin: 'Pre Login - First Banner - Login', // done | tested
            preLoginFirstBannerSignUp: 'Pre Login - First Banner - Sign up', // done | tested
            preLoginThirdBannerGetStarted: 'Pre Login - Third Banner - Get Started', // done | tested
            preLoginCallUsCall: 'Pre Login - Call Us - Call', // done | tested
            preLoginFourthBannerReadMore: 'Pre Login - Fourth Banner - Read More', // done | tested
            preLoginCountryLanguagePopUpSelectCountry: 'Pre Login - Country Language Pop-Up - Select Country', // done | tested
            preLoginCountryLanguagePopUpSelectLanguage: 'Pre Login - Country Language Pop-Up - Select Language', // done | tested
            preLoginCountryLanguagePopUpContinue: 'Pre Login - Country Language Pop-Up - Continue', // done | tested
            preLoginSignUpPopUpCancel: 'Pre Login - Sign up Pop Up - Cancel', // done | tested
            preLoginSignUpPopUpYes: 'Pre Login - Sign up Pop Up - Yes', // done | tested
            preLoginSignUpPopUpNo: 'Pre Login - Sign up Pop Up - No' // done | tested
        }
    },
    login: {
        categories: {
            login: 'Login'
        },
        actions: {
            loginForm: 'Login - Login Form'
        },
        labels: {
            loginFormLoginButton: 'Login - Login Form - Login Button', // done | tested
            loginFormGetStarted: 'Login - Login Form - Get Started', // done | tested
            loginFormChangeLanguageCountry: 'Login - Login Form - Change Language & Country', // done | tested
            loginFormSignUp: 'Login - Login Form - Sign Up', // done | tested
            loginFormResetPassword: 'Login - Login Form - Reset Password', // done | tested
            loginFormSendMeALink: 'Login - Login Form - Send me a link' // done | tested
        }
    },
    register: {
        categories: {
            register: 'Register'
        },
        actions: {
            registerForm: 'Register - Form'
        },
        labels: {
            registerFormLogin: 'Register - Form - Login', // done | tested
            registerFormVerify: 'Register - Form - Verify' // done | tested
        }
    },
    header: {
        categories: {
            header: 'Header'
        },
        actions: {
            cartView: 'Header - Cart View',
            myProfile: 'Header - My Profile',
            header: 'Header - Header',
            myOrdersReturns: 'Header - My Orders & Returns',
            myServices: 'Header - My Services',
            tips: 'Header - Tips',
            claimsTickets: 'Header - Claims & tickets',
            myRewards: 'Header - My rewards',
            shoppingList: 'Header - Shopping List'
        },
        labels: {
            cartViewMinicart: 'Header - Cart View - Minicart', // done | tested
            myProfileMyAccount: 'Header - My Profile - My Account', // done | tested
            myProfileLogout: 'Header - My Profile - Logout', // done | tested
            headerProducts: 'Header - Header - Products', // done | tested
            headerPromotion: 'Header - Header - Promotion', // done | tested
            headerMyOrdersReturns: 'Header - Header - My Orders & Returns', // done | tested
            headerMyServices: 'Header - Header - My Services', // done | tested
            headerMyEquipment: 'Header - Header - My equipment', // done | tested
            headerCoolerDashboard: 'Header - Header - Cooler Dahsboard', // done | tested
            headerLogo: 'Header - Header - Header Logo', // done | tested
            headerOutletChoice: 'Header - Header - Outlet Choice', // done | tested
            headerNotificationsAll: 'Header - Header - Notifications - All', // done | tested
            headerNotificationsUnread: 'Header - Header - Notifications - Unread', // done | tested
            headerNotificationsOpen: 'Header - Header - Notifications - Open', // done | tested
            headerNotificationsClose: 'Header - Header - Notifications - Close', // done | tested
            headerNexttAvailableDelivery: 'Header - Header - Next Available Delivery', // done | tested
            headerCreditIssue: 'Header - Header - Credit Issue', // done | tested
            myOrdersReturnsOrderHistory: 'Header - My Orders & Returns - Order History', // done | tested
            myOrdersReturnsOrderTemplate: 'Header - My Orders & Returns - Order Template', // done | tested
            myOrdersReturnsEmptyReturnProducts: 'Header - My Orders & Returns - Empty Return Products', // done | tested
            myServicesMyPayment: 'Header - My Services - My Payment', // done | tested
            myServicesClaimsTickets: 'Header - My Services - Claims & tickets', // done | tested
            myServicesRequestSupport: 'Header - My Services - Request Support', // done | tested
            myServicesTips: 'Header - My Services - Tips' // done | tested
        }
    },
    myAccount: {
        categories: {
            myAccount: 'My Account'
        },
        actions: {
            orderInformation: 'My Account - Order Information',
            shoppingList: 'My Account - Shopping List',
            myServices: 'My Account - My Services',
            myEquipment: 'My Account - My equipment',
            myRewards: 'My Account - My rewards',
            accountSettings: 'My Account - Account Settings'
        },
        labels: {
            orderInformationOrderHistory: 'My Account - Order Information - Order History', // done | tested
            orderInformationInvoices: 'My Account - Order Information - Invoices', // done | tested
            shoppingListOrderTemplate: 'My Account - Shopping List - Order Template', // done | tested
            shoppingListQuickOrder: 'My Account - Shopping List - Quick Order', // done | tested
            myServicesRequestSupport: 'My Account - My Services - Request Support', // done | tested
            myEquipmentCoolerDashboard: 'My Account - My equipment - Cooler Dahsboard', // done | tested
            myRewards: 'My Account - My rewards - My rewards', // done | tested
            accountSettingsPersonalData: 'My Account - Account Settings - Personal Data', // done | tested
            accountSettingsCustomerFactSheet: 'My Account - Account Settings - Customer Fact Sheet', // done | tested
            accountSettingsCompanyData: 'My Account - Account Settings - Company Data', // done | tested
            accountSettingsUserManagement: 'Account Settings - Account Settings - User Management' // done | tested
        }
    },
    accountSettings: {
        categories: {
            accountSettings: 'Account Settings'
        },
        actions: {
            accountSettings: 'Account Settings - Account Settings',
            companyData: 'Account Settings - Company Data',
            editPassword: 'Account Settings - Edit Password',
            personalData: 'Account Settings - Personal data',
            userManagement: 'Account Settings - User Management'
        },
        labels: {
            accountSettingsPersonalData: 'Account Settings - Account Settings - Personal Data', // done | tested
            accountSettingsEditPassword: 'Account Settings - Account Settings - Edit password', // done | tested
            accountSettingsCustomerFactSheet: 'Account Settings - Account Settings - Customer Fact Sheet', // done | tested
            accountSettingsCompanyData: 'Account Settings - Account Settings - Company Data', // done | tested
            accountSettingsUserManagement: 'Account Settings - Account Settings - User Management', // done | tested
            companyDataPricingPreferenceEdit: 'Account Settings - Company Data - Pricing Preference Edit', // done | tested
            companyDataPricingPreferenceSave: 'Account Settings - Company Data - Pricing Preference Save', // done | tested
            companyDataPricingPreferenceCancel: 'Account Settings - Company Data - Pricing Preference Cancel', // done | tested
            editPasswordUpdatePassword: 'Account Settings - Edit Password - Update password', // done | tested
            editPasswordCancel: 'Account Settings - Edit Password - Cancel', // done | tested
            personalDataTransactionalNotificationsEdit:
                'Account Settings - Personal data - Transactional Notifications Edit', // done | tested
            personalDataTransactionalNotificationsSave:
                'Account Settings - Personal data - Transactional Notifications Save', // done | tested
            personalDataTransactionalNotificationsCancel:
                'Account Settings - Personal data - Transactional Notifications Cancel', // done | tested
            personalDataMiniCartConfigurationEdit: 'Account Settings - Personal data - Mini cart configuration Edit', // done | tested
            personalDataMiniCartConfigurationSave: 'Account Settings - Personal data - Mini cart configuration Save', // done | tested
            personalDataMiniCartConfigurationCancel:
                'Account Settings - Personal data - Mini cart configuration Cancel', // done | tested
            userManagementAddNewUser: 'Account Settings - User Management - Add New users', // done | tested
            userManagementEdit: 'Account Settings - User Management - Edit', // done | tested
            userManagementDelete: 'Account Settings - User Management - Delete' // done | tested
        }
    },
    userManagement: {
        categories: {
            userManagement: 'User Management'
        },
        actions: {
            userManagementAddNewUser: 'User Management - Add New users'
        },
        labels: {
            userManagementAddNewUserRegister: 'User Management - Add New users - Register', // done | tested
            userManagementAddNewUserCangel: 'User Management - Add New users - Cancel' // done | tested
        }
    },
    fsa: {
        categories: {
            tipsNTricks: 'Tips&Tricks'
        },
        actions: {
            getStarted: 'FSA - Get Started',
            learnMore: 'FSA - Learn More',
            downloadImages: 'FSA - Download Images',
            optionalGuidance: 'FSA - Operational Guidance',
            readyToStart: 'FSA - Ready to Start'
        },
        labels: {
            getStartedPrefix: 'FSA - Get Started - ', // done | tested
            getStartedOpenPopUp: 'FSA - Get Started - Open Pop-Up', // done | tested
            getStartedClosePopUp: 'FSA - Get Started - Close Pop-Up', // done | tested
            learnMoreOpenPopup: 'FSA - Learn More - Open Pop-Up', // done | tested
            learnMoreClosePopup: 'FSA - Learn More - Close Pop-Up', // done | tested
            learnMoreAnchorTopTips: 'FSA - Learn More - Anchor Top Tips', // done | tested
            downloadImages: 'FSA - Download Images', // done | tested
            operationalGuidancePrefix: 'FSA - Operational Guidance - ', // done | tested
            readyToStartGetStarted: 'FSA - Ready to Start - Get Started', // done | tested
            readyToStartCheckOurTips: 'FSA - Ready to Start - Check Our Tips' // done | tested
        }
    },
    products: {
        categories: {
            products: 'Products'
        },
        actions: {
            sort: 'Products - Sort',
            productCard: 'Products - Product Card',
            savedFilters: 'Products - Saved Filters',
            productTile: 'Products - Product tile',
            footer: 'Products - Footer',
            filterSelection: 'Products - Filter Selection',
            productView: 'Products - Product View'
        },
        labels: {
            sortSortClick: 'Products - Sort - Sort Click', // done | tested
            sortSortAll: 'Products - Sort - ***', // done | tested
            sortSortAZ: 'Products - Sort - A-Z', // done | tested
            sortSortNewest: 'Products - Sort - Newest', // done | tested
            sortSortPriceHighToLow: 'Products - Sort - Price High to Low', // done | tested
            sortSortPriceLowToHigh: 'Products - Sort - Price Low to High', // done | tested
            sortSortZA: 'Products - Sort - Z-A', // done | tested
            productCardPlus: 'Products - Product Card - Plus Button', // done | tested
            productCardMinus: 'Products - Product Card - Minus Button', // done | tested
            productCardQuantityInput: 'Products - Product Card - Quantity Input', // done | tested
            productCardAddToCart: 'Products - Product Card - Add to Cart Button', // done | tested
            savedFiltersActionConfirmationPopUp: 'Products - Saved Filters - Action Confirmation Pop Up', // done | tested
            savedFiltersClosePopUp: 'Products - Saved Filters - Close Pop Up', // done | tested
            savedFiltersDeleteSaved: 'Products - Saved Filters - Delete Saved', // done | tested
            savedFiltersMakeDefault: 'Products - Saved Filters - Make Default', // done | tested
            savedFiltersUpdateSaved: 'Products - Saved Filters - Update Saved', // done | tested
            productTileAddToFavorites: 'Products - Product tile - Add to Favorites', // done | tested
            productTileAddToOrderTemplate: 'Products - Product tile - Add to Order Template', // done | tested
            productTileRemoveFromFavorites: 'Products - Product tile - Remove from Favorites', // done | tested
            productTileLogisticUnitSelection: 'Products - Product tile - Logistic Unit Selection', // done | tested
            productTileProductSelection: 'Products - Product Tile - Product Selection', // done | tested
            footerBackToTop: 'Products - Footer - Back to Top', // done | tested
            filterSelectionClearAllFilters: 'Products - Filter Selection - Clear All Filters', // done | tested
            filterSelectionClearSingleFilter: 'Products - Filter Selection - Clear Single Filter', // done | tested
            filterSelectionShowFilters: 'Products - Filter Selection - Show Filters', // done | tested
            filterSelectionHideFilters: 'Products - Filter Selection - Hide Filters', // done | tested
            filterSelectionSaveFilters: 'Products - Filter Selection - Save Filters', // done | tested
            filterSelectionSavedFilters: 'Products - Filter Selection - Saved Filters', // done | tested
            filterSelectionViewResults: 'Products - Filter Selection - View Results', // done | tested
            filterSelectionReccomendations: 'Products - Filter Selection - Reccomendations', // done | tested
            productViewGridView: 'Products - Product View - Grid View', // done | tested
            productViewListView: 'Products - Product View - List View' // done | tested
        }
    },
    cart: {
        categories: {
            cart: 'Cart'
        },
        actions: {
            view: 'Cart - View',
            productCard: 'Cart - Product Card',
            outOfStockPopUp: 'Cart - Out of Stock Pop-Up',
            shoppingCart: 'Cart - Shopping Cart'
        },
        labels: {
            cartViewContinueToCheckout: 'Cart - Cart View - Continue to Checkout', // done | tested
            cartViewClearAllCarts: 'Cart - Cart View - Clear All Carts', // done | tested
            cartViewChangeDeliveryDate: 'Cart - Cart View - Change Delivery Date', // done | tested
            productCardPlus: 'Cart - Product Card - Plus Button', // done | tested
            productCardMinus: 'Cart - Product Card - Minus Button', // done | tested
            productCardQuantityInput: 'Cart - Product Card - Quantity Input', // done | tested
            productCardDelete: 'Cart - Product Card - Delete Button', // done | tested
            outOfStockPopUpKeepItems: 'Cart - Out of Stock Pop-Up - Keep Items', // done | tested
            outOfStockPopUpReduceItems: 'Cart - Out of Stock Pop-Up - Reduce/Remove Items', // done | tested
            outOfStockPopUpExitPopUp: 'Cart - Out of Stock Pop-Up - Exit Pop-Up', // done | tested
            shoppingCartRemoveOOSItems: 'Cart - Shopping Cart - Remove OOS Items', // done | tested
            shoppingCartAddToOrderTemplate: 'Cart - Shopping Cart - Add to Order Template' // done | tested
        }
    },
    minicart: {
        categories: {
            minicart: 'Minicart'
        },
        actions: {
            continueToCart: 'Minicart - Continue to Cart',
            clearAllCarts: 'Minicart - Clear All Carts',
            changeDeliveryDate: 'Minicart - Change Delivery Date',
            productCard: 'Minicart - Product Card',
            settings: 'Minicart - Settings',
            returnToShopping: 'Minicart - Return to Shopping',
            clearAllPopUp: 'Minicart - Clear All Pop Up',
            deliveryOptions: 'Minicart - Delivery Options'
        },
        labels: {
            continueToCart: 'Minicart - Continue to Cart - Continue to Cart', // done | tested
            clearAllCarts: 'Minicart - Clear All Carts - Clear All Carts', // done | tested
            changeDeliveryDate: 'Minicart - Change Delivery Date - Change Delivery Date', // done | tested
            productCardPlus: 'Minicart - Product Card - Plus Button', // done | tested
            productCardMinus: 'Minicart - Product Card - Minus Button', // done | tested
            productCardQuantityInput: 'Minicart - Product Card - Quantity Input', // done | tested
            productCardDelete: 'Minicart - Product Card - Delete Button', // done | tested
            settingsAccountSettings: 'Minicart - Settings - Account Settings', // done | tested
            returnToShopping: 'Minicart - Return to Shopping - Return to Shopping', // done | tested
            clearAllPopUpCancel: 'Minicart - Clear All Pop Up - Cancel' // done | tested
        }
    },
    home: {
        categories: {
            home: 'Homepage'
        },
        actions: {
            myRecentOrders: 'Homepage - My Recent Orders',
            favoriteProductCard: 'Homepage - Favorite Product Card',
            creditIssue: 'Homepage - Credit Issue',
            smartOrderNotification: 'Homepage - Smart Order Notification',
            discoverSmartOrder: 'Homepage - Discover Smart Order',
            reorder: 'Homepage - Reorder'
        },
        labels: {
            myRecentOrdersReorder: 'Homepage - My Recent Orders - Reorder', // done | tested
            favoriteProductCardPlus: 'Homepage - Favorite Product Card - Plus Button', // done | tested
            favoriteProductCardMinus: 'Homepage - Favorite Product Card - Minus Button', // done | tested
            favoriteProductCardQuantityInput: 'Homepage - Favorite Product Card - Quantity Input', // done | tested
            favoriteProductCardAddToCart: 'Homepage - Favorite Product Card - Add to Cart Button', // done | tested
            creditIssueCheckYourInvoices: 'Homepage - Credit Issue - Check your invoices', // done | tested
            creditIssueCallUs: 'Homepage - Credit Issue - Call Us', // done | tested
            creditIssueExit: 'Homepage - Credit Issue - Exit', // done | tested
            smartOrderNotificationSmartOrder: 'Homepage - Smart Order Notification - Smart Order', // done | tested
            smartOrderNotificationExit: 'Homepage - Smart Order Notification - Exit', // done | tested
            discoverSmartOrderViewAllProducts: 'Homepage - Discover Smart Order - View All Products', // done | tested
            discoverSmartOrderSmartOrder: 'Homepage - Discover Smart Order - Smart Order', // done | tested
            reorderViewOrder: 'Homepage - Reorder - View Order' // done | tested
        }
    },
    order: {
        categories: {
            order: 'Order'
        },
        actions: {
            details: 'Order - Details'
        },
        labels: {
            detailsReorder: 'Order - Order Details - Reorder' // done | tested
        }
    },
    footer: {
        categories: {
            footer: 'Footer'
        },
        actions: {
            chat: 'Footer - Chat',
            quickLinks: 'Footer - Quick Links',
            contactUsPopUp: 'Footer - Contact Us Pop Up',
            bdPopUp: 'Footer - BD Pop Up'
        },
        labels: {
            chatStartChat: 'Footer - Chat - Start Chat', // done | tested
            quickLinksAccountSettings: 'Footer - Quick Links - Account Settings', // done | tested
            quickLinksContactUs: 'Footer - Quick Links - Contact Us', // done | tested
            quickLinksFAQ: 'Footer - Quick Links - FAQ', // done | tested
            quickLinksImprint: 'Footer - Quick Links - Imprint', // done | tested
            quickLinksMyAccount: 'Footer - Quick Links - My Account', // done | tested
            quickLinksMyServices: 'Footer - Quick Links - My Services', // done | tested
            quickLinksOrderInformation: 'Footer - Quick Links - Order Information', // done | tested
            quickLinksOurCompany: 'Footer - Quick Links - Our Company', // done | tested
            quickLinksPrivacyCookiePolicy: 'Footer - Quick Links - Privacy & Cookie Policy', // done | tested
            quickLinksProducts: 'Footer - Quick Links - Products', // done | tested
            quickLinksPromotions: 'Footer - Quick Links - Promotions', // done | tested
            quickLinksRequestSupport: 'Footer - Quick Links - Request Support', // done | tested
            quickLinksSalesRepresentative: 'Footer - Quick Links - Sales Representative', // done | tested
            quickLinksShoppingList: 'Footer - Quick Links - Shopping List', // done | tested
            quickLinksTermsConditions: 'Footer - Quick Links - Terms & Conditions', // done | tested
            bdPopUpCancel: 'Footer - BD Pop Up - Cancel', // done | tested
            bdPopupPhoneNumberClick: 'Footer - BD Pop Up - Phone Number Click', // done | tested
            contactUsPopUpCancel: 'Footer - Contact Us Pop Up - Cancel', // done | tested
            contactUsPopUpPhoneNumberClick: 'Footer - Contact Us Pop Up - Phone Number Click' // done | tested
        }
    },
    cooler: {
        categories: {
            cooler: 'Cooler'
        },
        actions: {
            tips: 'Cooler - Tips'
        },
        labels: {
            tipsNexTip: 'Cooler - Tips - Next Tip' // done | tested
        }
    },
    FAQ: {
        categories: {
            FAQ: 'FAQ'
        },
        actions: {
            questions: 'FAQ - Questions'
        },
        labels: {
            questionsDownButton: 'FAQ - Questions - Down Button', // done | tested
            questionsUpButton: 'FAQ - Questions - Up Button' // done | tested
        }
    },
    orderTemplates: {
        categories: {
            orderTemplates: 'Order Templates'
        },
        actions: {
            orderTemplates: 'Order Templates - Order Templates',
            createNew: 'Order Templates - Create New',
            templateDetails: 'Order Templates - Template Details',
            list: 'Order Templates - List'
        },
        labels: {
            orderTemplatesCreateNew: 'Order Templates - Order Templates - Create New', // done | tested
            orderTemplatesBrowse: 'Order Templates - Order Templates - Browse', // done | tested
            orderTemplatesCatalogue: 'Order Templates - Order Templates - Catalogue', // done | tested
            createNewCreate: 'Order Templates - Create New - Create', // done | tested
            createNewCancel: 'Order Templates - Create New - Cancel', // done | tested
            createNewExit: 'Order Templates - Create New - Exit', // done | tested
            templateDetailsPrint: 'Order Templates - Template Details - Print', // done | tested
            templateDetailsCopy: 'Order Templates - Template Details - Copy', // done | tested
            templateDetailsUpdate: 'Order Templates - Template Details - Update', // done | tested
            listRestore: 'Order Templates - List - Restore', // done | tested
            listDelete: 'Order Templates - List - Delete' // done | tested
        }
    },
    SLorderTemplates: {
        categories: {
            orderTemplates: 'SL Order Templates'
        },
        actions: {
            orderTemplates: 'SL Order Templates - Order Templates',
            createNew: 'SL Order Templates - Create New',
            templateDetails: 'SL Order Templates - Template Details',
            list: 'SL Order Templates - List'
        },
        labels: {
            orderTemplatesCreateNew: 'SL Order Templates - Order Templates - Create New', // done | tested
            createNewCreate: 'SL Order Templates - Create New - Create', // done | tested
            createNewCancel: 'SL Order Templates - Create New - Cancel', // done | tested
            createNewExit: 'SL Order Templates - Create New - Exit', // done | tested
            templateDetailsPrint: 'SL Order Templates - Template Details - Print', // done | tested
            templateDetailsCopy: 'SL Order Templates - Template Details - Copy', // done | tested
            templateDetailsUpdate: 'SL Order Templates - Template Details - Update', // done | tested
            listRestore: 'SL Order Templates - List - Restore', // done | tested
            listDelete: 'SL Order Templates - List - Delete' // done | tested
        }
    },
    templateDetails: {
        categories: {
            templateDetails: 'Template Details'
        },
        actions: {
            copy: 'Template Details - Copy'
        },
        labels: {
            copyCreate: 'Template Details - Copy - Create', // done | tested
            copyCancel: 'Template Details - Copy - Cancel', // done | tested
            copyExit: 'Template Details - Copy - Exit' // done | tested
        }
    },
    quickOrder: {
        categories: {
            quickOrder: 'Quick Order'
        },
        actions: {
            quickOrder: 'Quick Order - Quick Order'
        },
        labels: {
            quickOrderBrowse: 'Quick Order - Quick Order - Browse', // done | tested
            quickOrderCatalogue: 'Quick Order - Quick Order - Catalogue' // done | tested
        }
    },
    orderInformation: {
        categories: {
            orderInformation: 'Order Information'
        },
        actions: {
            orderHistory: 'Order Information - Order History',
            invoices: 'Order Information - Invoices'
        },
        labels: {
            orderInformationOrderHistoryReorder: 'Order Information - Order History - Reorder', // done | tested
            orderHistory: 'Order Information - Order History - Order History', // done | tested
            invoices: 'Order Information - Invoices - Invoices' // done | tested
        }
    },
    orderDetails: {
        categories: {
            orderDetails: 'Order Details'
        },
        actions: {
            title: 'Order Details - Title'
        },
        labels: {
            titlePrint: 'Order Details - Title - Print' // done | tested
        }
    },
    orderHistory: {
        categories: {
            orderHistory: 'Order History'
        },
        actions: {
            search: 'Order History - Search',
            list: 'Order History - List'
        },
        labels: {
            searchSearchButton: 'Order History - Search - Search Button', // done | tested
            searchClearButton: 'Order History - Search - Clear Button', // done | tested
            searchTimeframeArrow: 'Order History - Search - Timeframe Arrow', // done | tested
            searchOrderTypeArrow: 'Order History - Search - Order Type Arrow', // done | tested
            listAllSelection: 'Order History - List - All Selection', // done | tested
            listOpenSelection: 'Order History - List - Open Selection', // done | tested
            listCompletedSelection: 'Order History - List - Completed Selection', // done | tested
            listOrderNumber: 'Order History - List - Order Number' // done | tested
        }
    },
    myServices: {
        categories: {
            myServices: 'My Services'
        },
        actions: {
            claimsTickets: 'My Services - Claims & Tickets',
            shoppingList: 'My Services - Shopping List'
        },
        labels: {
            claimsTickets: 'My Services - Claims & Tickets - Claims & tickets', // done | tested
            claimsTicketsRequestSupport: 'My Services - Claims & Tickets - Request Support', // done | tested
            shoppingListOrderTemplate: 'My Services - Shopping List - Order Template', // done | tested
            shoppingListMyFavourites: 'My Services - Shopping List - My Favourites', // done | tested
            shoppingListQuickOrder: 'My Services - Shopping List - Quick Order', // done | tested
            shoppingListMyReccomendations: 'My Services - Shopping List - My Reccomendations' // done | tested
        }
    },
    claimTicketsHistory: {
        categories: {
            claimTicketsHistory: 'Claim & Tickets History'
        },
        actions: {
            search: 'Claim & Tickets History - Search',
            list: 'Claim & Tickets History - List'
        },
        labels: {
            searchTimePeriodArrow: 'Claim & Tickets History - Search - Time Period Arrow', // done | tested
            searchTypeArrow: 'Claim & Tickets History - Search - Type Arrow', // done | tested
            searchSearchButton: 'Claim & Tickets History - Search - Search Button', // done | tested
            searchClearButton: 'Claim & Tickets History - Search - Clear Button', // done | tested
            listAllSelection: 'Claim & Tickets History - List - All Selection', // done | tested
            listOpenSelection: 'Claim & Tickets History - List - Open Selection', // done | tested
            listCompletedSelection: 'Claim & Tickets History - List - Completed Selection' // done | tested
        }
    },
    invoice: {
        categories: {
            invoice: 'Invoice'
        },
        actions: {
            search: 'Invoice - Search',
            list: 'Invoice - List'
        },
        labels: {
            searchStatusArrow: 'Invoice - Search - Status Arrow', // done | tested
            searchTimePeriodArrow: 'Invoice - Search - Time Period Arrow', // done | tested
            searchCustomerArrow: 'Invoice - Search - Customer Arrow', // done | tested
            searchViewResults: 'Invoice - Search - View Results', // done | tested
            searchReset: 'Invoice - Search - Reset', // done | tested
            searchAdvancedFilters: 'Invoice - Search - Advanced Filters', // done | tested
            listCreateClaim: 'Invoice - List - Create a Claim', // done | tested
            listDownload: 'Invoice - List - Download', // done | tested
            listSelectAll: 'Invoice - List - Select All' // done | tested
        }
    },
    checkout: {
        categories: {
            checkout: 'Checkout'
        },
        actions: {
            checkout: 'Checkout - Checkout',
            orderSummary: 'Checkout - Order Summary',
            referenceData: 'Checkout - Reference data',
            orderConfirmation: 'Checkout - Order Confirmation'
        },
        labels: {
            checkoutArrowSelection: 'Checkout - Checkout - Arrow Selection', // done | tested
            orderSummaryTermsConditions: 'Checkout - Order Summary - Terms&Conditions', // done | tested
            referenceDataSaveNote: 'Checkout - Reference data - Save Note', // done | tested
            orderConfirmationPrint: 'Checkout - Order Confirmation - Print' // done | tested
        }
    },
    promotions: {
        categories: {
            promotions: 'Promotions'
        },
        actions: {
            promotionTitle: 'Promotions - Promotion Tile'
        },
        labels: {
            promotionTitleShopProducts: 'Promotions - Promotion Tile - Shop Products' // done | tested
        }
    }
};

export const buttonClickEvent = (action = '', label = '', category = '') => {
    try {
        if (!ga4enabled) {
            return;
        }

        const buttonClickEventData = {
            event: 'button_click',
            event_category: category,
            event_action: action,
            event_label: label
        };

        pushDataLayerEventData(buttonClickEventData);
    } catch (error) {
        console.error(error);
    }
};
